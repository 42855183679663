<template>
  <!-- 标品预约信息 -->
  <div class="standardInfo">
    <div class="flex_between bbe9s">
      <div class="title f-left p10">标品预约信息</div>
    </div>
    <!-- 头部筛选 -->
    <div class="mt10  pb20 filterPr" style="display:flex">
      <!-- 筛选订单 -->
      <div class="blocks2 flex_center">
        <el-input class="mr20" size="small" placeholder="请输入内容" v-model="chosecon">
          <el-select v-model="selectkind" slot="prepend" :clearable="true" placeholder="请选择类别" style="width: 120px"
            @change="categoryChange">
            <el-option label="联系人" value="contact"></el-option>
            <el-option label="联系电话" value="mobile"></el-option>
            <el-option label="订单编号" value="order_no"></el-option>
            <el-option label="维修地址" value="address"></el-option>
          </el-select>
        </el-input>

        <el-date-picker v-model="createTime" size="small" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <!-- 搜索和导出 -->
      <div class="ml20 flex_center">
        <!-- 搜索 -->
        <div class=" mr10">
          <el-button size="small" type="primary" @click="searchStandard">搜索</el-button>
        </div>
        <!-- >导出 -->
        <div>
          <download-excel class="export-excel-wrapper" :data="excelData" :fields="json_fields" :header="title"
            name="标品预约信息.xls">
            <el-button size="small" type="success">导出</el-button>
          </download-excel>
        </div>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="t1 orderList">
      <div class="block flex_column">
        <!-- 内容 -->
        <div>
          <el-table :data="tableData" border style="width: 100%" :row-style="{ height: '50px' }"
            :header-cell-style="{ color: '#252525' }">
            <el-table-column align="center" type="index" label="序号" width="100" height="200">
            </el-table-column>
            <el-table-column align="center" prop="order_no" label="订单编号" width="250">
            </el-table-column>
            <el-table-column align="center" prop="addres" label="服务地址" width="350">
            </el-table-column>
            <el-table-column align="center" prop="contact" label="客户姓名" width="150">
            </el-table-column>
            <el-table-column align="center" prop="mobile" label="联系电话" width="180">
            </el-table-column>
            <el-table-column align="center" prop="createTime" label="创建时间" width="180">
            </el-table-column>
            <el-table-column align="center" prop="titles" label="服务内容">
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="flex_start mt20 flex_items">
          <span class="demonstration mr20">
            总共
            <span class="f-red">{{ total }}</span>条数据,当前 <span class="f-red">{{ tableData.length || 0 }}</span>条
          </span>
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNum" :page-size="pageSize"
            :total="total" layout="prev, pager, next, jumper"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'standardInfo',
  props: {},
  components: {},
  data() {
    return {
      // 头部筛选
      chosecon: '', // input 值
      selectkind: '', // 下拉框 键
      createTime: '', // 头部时间 参数时间戳

      // 表格列表
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      // 导出excel文件
      excelData: [], // 需要导出的数据
      title: '标品预约信息', // 表格标题
      json_fields: {
        订单编号: 'order_no',
        服务地址: 'addres',
        客户姓名: 'contact',
        联系电话: 'mobile',
        创建时间: 'createTime',
        服务内容: 'titles'
      } // 里面的属性是excel表每一列的title,用多个词组组成的属性名(中间有空格的)要加双引号; 指定接口的json内某些数据下载,若不指定，默认导出全部数据中心全部字段
    }
  },
  created() { },
  watch: {},
  mounted() {
    this.getStandardInfoList()
  },
  methods: {
    // 任务类型下拉框
    categoryChange(val) {
      this.chosecon = ''
    },
    // 点击分页
    handleCurrentChange(val) {
      console.log('handleCurrentChange', val)
      this.pageNum = val
      this.getStandardInfoList()
    },
    // 获取标品表格数据
    getStandardInfoList() {
      this.$loading(this.util.opload)
      let params = {
        limit: this.pageSize,
        page: this.pageNum,
        chen: this.selectkind, // 根据类型筛选 chen 后端的key
        contacts: this.chosecon, // contcts 后端的值
        createTime: new Date(this.createTime).getTime() / 1000 || '' // 根据时间筛选 时间戳
      }
      this.util.get(this.HOST + '/productsOrder/list', params).then((res) => {
        if (res.code !== 200) return this.$message.error(res.msg)
        this.total = res.data.count
        this.tableData = res.data.list
        setTimeout(() => {
          this.ExcelData()
        })
      })
      this.$loading().close()
    },
    // 筛选搜索
    searchStandard() {
      this.getStandardInfoList()
    },
    //导出文件单独的请求  导出所有数据
    ExcelData() {
      let params = {
        limit: this.total,
        page: 1,
        chen: this.selectkind, // 根据类型筛选 chen 后端的key
        contacts: this.chosecon, // contcts 后端的值
        createTime: new Date(this.createTime).getTime() / 1000 || '' // 根据时间筛选 时间戳
      }
      this.util.get(this.HOST + '/productsOrder/list', params).then((res) => {
        if (res.code !== 200) return this.$message.error(res.msg)
        //导出数据表格
        this.excelData = res.data.list
        // jsonExcel导出数字过长辉在文档上显示科学计数  因此把数字转换下
        this.excelData.map((v) => {
          v.order_no = v.order_no + '&nbsp;'
        })
        console.log('this.tableData', this.excelData);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.standardInfo {
  margin: 0 auto;
  background: white;
  padding: 0 30px 30px 35px;
  border-radius: 8px;
  z-index: -99;

  /deep/ .el-date-editor.el-input {
    width: 300px;
  }
}
</style>
